import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Input from '@base/Input';
import { required } from '@src/utilities/validations';
import FormContainer from '@components/FormContainer';

function NameForm({ onComplete }) {
  return (
    <FinalForm
      onSubmit={async ({ familyName, givenName }) => {
        try {
          await onComplete({ familyName, givenName });
        } catch (e) {
          return {
            [FORM_ERROR]: e.message,
          };
        }
      }}
      render={({ handleSubmit, submitting, submitError }) => (
        <FormContainer
          handleSubmit={handleSubmit}
          submitError={submitError}
          submitLabel="Next"
          submitting={submitting}
        >
          <Field
            component={Input}
            disabled={submitting}
            label="First Name"
            name="givenName"
            required
            validate={required}
          />
          <Field
            component={Input}
            disabled={submitting}
            label="Last Name"
            name="familyName"
            required
            validate={required}
          />
        </FormContainer>
      )}
    />
  );
}

NameForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
export default NameForm;
