import React from 'react';
import PropTypes from 'prop-types';
import { Field, Form as FinalForm } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import Input from '@base/Input';
import {
  composeValidations,
  email,
  required,
} from '@src/utilities/validations';
import FormContainer from '@components/FormContainer';

function EmailForm({ onComplete }) {
  return (
    <FinalForm
      onSubmit={async (values) => {
        const val = values.email;
        try {
          await onComplete(val);
        } catch (e) {
          return {
            [FORM_ERROR]: e.message,
          };
        }
      }}
      render={({ handleSubmit, submitting, submitError }) => (
        <FormContainer
          handleSubmit={handleSubmit}
          submitError={submitError}
          submitLabel="Send Code"
          submitting={submitting}
        >
          <Field
            component={Input}
            disabled={submitting}
            label="Email"
            name="email"
            required
            validate={composeValidations(required, email)}
          />
        </FormContainer>
      )}
    />
  );
}

EmailForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
};
export default EmailForm;
